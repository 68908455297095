import React, { useEffect } from 'react';
import { getResourceCount } from '../../../db/utils';
import { hasDayPassed, showExpiryNotification } from '../../../helpers/utils';
import { APP_ROLES } from '../../../helpers/roles';
import { useLocalStorage } from 'react-use';
import Notification from '../Notification';
import { Text } from 'mpharma-i18n';

const ExpiredProductsNotification = ({ user, setOpenExpiredProductsNotification, openExpiredProductsNotification }) => {
  const [expiredListUpdated, setExpiredListUpdated] = useLocalStorage('bloom:expiredListUpdated');

  async function getExpiredProductsCount() {
    const expiredProductsCount = await getResourceCount('expired_products');
    if (expiredProductsCount > 0 && hasDayPassed(expiredListUpdated)) {
      setOpenExpiredProductsNotification(true);
    }
  }

  useEffect(() => {
    if (!user?.scopes?.includes(APP_ROLES.admin)) getExpiredProductsCount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {openExpiredProductsNotification && showExpiryNotification(window.location.pathname) && (
        <Notification
          action={<Text i18nKey="notifications.expiredProducts.action">View the list</Text>}
          link="/inventory/expired-products"
          message={
            <Text i18nKey="notifications.expiredProducts.message">You have a number of products that have expired</Text>
          }
          critical
          handleClose={() => {
            setExpiredListUpdated(new Date().toISOString());
            setOpenExpiredProductsNotification(false);
          }}
        />
      )}
      ;
    </>
  );
};

export default ExpiredProductsNotification;
