import React from 'react';
import { showCheckFINotification } from '../../../helpers/utils';
import { ReactComponent as GreenSmiley } from '../../../images/GreenSmiley.svg';
import { Text } from 'mpharma-i18n';
import Notification from '../Notification';

const FacilityInsightsReminderNotification = ({
  user,
  showFIReminder,
  updateNextFIReminderDate,
  setshowFIReminder
}) => {
  return (
    <>
      {showFIReminder && showCheckFINotification(window.location.pathname, user) && (
        <Notification
          action={<Text i18nKey="notifications.checkFacilityInsights.action">View the list</Text>}
          link="/facility-insights/overview?from=reminder"
          message={
            <>
              <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                <Text i18nKey="notifications.checkFacilityInsights.title">
                  Have you checked your profits for this week?
                </Text>
              </b>
              <br />
              <Text i18nKey="notifications.checkFacilityInsights.subtitle">Open Facility Insights to check.</Text>
            </>
          }
          textStyle={{ width: 'unset', lineHeight: '1.7em' }}
          icon={<GreenSmiley style={{ margin: 2 }} />}
          success
          handleClose={() => {
            updateNextFIReminderDate();
            setshowFIReminder(false);
          }}
        />
      )}
    </>
  );
};

export default FacilityInsightsReminderNotification;
