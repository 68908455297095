import React from 'react';
import { showMpharmaWrappedPrompt } from '../../../helpers/utils';
import { ReactComponent as GreenSmiley } from '../../../images/GreenSmiley.svg';
import { Text } from 'mpharma-i18n';
import Notification from '../Notification';

const WrappedNotification = ({ user, showWrappedNotification, hideWrappedNotification }) => {
  return (
    <>
      {showWrappedNotification && showMpharmaWrappedPrompt(user) && (
        <Notification
          action={<Text i18nKey="notifications.mPharmaWrapped.action">Get Started</Text>}
          link="/facility-insights/reports?i=wrapped"
          message={
            <>
              <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                <Text i18nKey="notifications.mPharmaWrapped.title">Introducing the new #mPharmaWrapped</Text>
              </b>
              <br />
              <Text i18nKey="notifications.mPharmaWrapped.subtitle">Time to reflect on the past year</Text>
            </>
          }
          textStyle={{ width: 'unset', lineHeight: '1.7em' }}
          icon={<GreenSmiley style={{ margin: 2 }} />}
          success
          handleClose={() => {
            hideWrappedNotification();
          }}
        />
      )}
    </>
  );
};

export default WrappedNotification;
