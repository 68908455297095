import React, { useEffect } from 'react';
import { hasDayPassed, showExpiryNotification } from '../../../helpers/utils';
import { APP_ROLES } from '../../../helpers/roles';
import { useLocalStorage } from 'react-use';
import Notification from '../Notification';
import { Text } from 'mpharma-i18n';
import { getResourceCount } from '../../../db/utils';

const ProductsExpiringSoonNotification = ({ user, openExpiringSoonNotification, setOpenExpiringSoonNotification }) => {
  const [expiringListUpdated, setExpiringListUpdated] = useLocalStorage('bloom:expiringListUpdated');

  async function getExpiringProductsCount() {
    const expiringProductsCount = await getResourceCount('expiring_soon');
    if (expiringProductsCount > 0 && hasDayPassed(expiringListUpdated)) {
      setOpenExpiringSoonNotification(true);
    }
  }

  useEffect(() => {
    if (!user?.scopes?.includes(APP_ROLES.admin)) getExpiringProductsCount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {openExpiringSoonNotification && showExpiryNotification(window.location.pathname) && (
        <Notification
          action={<Text i18nKey="notifications.expiringSoon.action">View the list</Text>}
          link="/inventory/expiring-soon"
          message={
            <Text i18nKey="notifications.expiringSoon.message">
              You have a number of products that are expiring soon
            </Text>
          }
          warning
          handleClose={() => {
            setExpiringListUpdated(new Date().toISOString());
            setOpenExpiringSoonNotification(false);
          }}
        />
      )}
    </>
  );
};

export default ProductsExpiringSoonNotification;
