import React, { useEffect } from 'react';
import { ReactComponent as GreenSmiley } from '../../../images/GreenSmiley.svg';
import { Text } from 'mpharma-i18n';
import Notification from '../Notification';
import * as Sentry from '@sentry/react';
import { API_ROOT } from '../../../helpers/constants';
import { get } from '../../../admin/api';
import MCAFeedbackDialog from '../MCAFeedbackDialog/MCAFeedbackDialog';
import useOfflineStatus from '../../../hooks/useOfflineStatus';

const MCAFeedbackNotification = ({
  user,
  newMCAFeedback,
  setShowMCAFeedbackDialog,
  setNewMCAFeedback,
  showMCAFeedbackDialog
}) => {
  const { online } = useOfflineStatus();

  useEffect(() => {
    if (user && online) {
      getMCAFeedback();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function getMCAFeedback() {
    const BASE_URL = `${API_ROOT}/insights-bff/performance/mca-user-reviews?user_id=${user?.id}`;
    try {
      const mcaFeedbackData = await get(BASE_URL, { withCredentials: true });
      if (mcaFeedbackData && mcaFeedbackData?.results[0]?.is_read !== true) {
        setNewMCAFeedback(mcaFeedbackData?.results[0]);
      } else {
        setNewMCAFeedback(false);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  return (
    <>
      {newMCAFeedback && (
        <Notification
          action={<Text i18nKey="notifications.MCAFeedback.action">See feedback</Text>}
          link={() => setShowMCAFeedbackDialog(true)}
          message={
            <>
              <b style={{ fontFamily: 'Sofia Pro Bold' }}>
                <Text
                  i18nKey="home.salutation"
                  variables={{
                    firstName: `${user?.first_name}`
                  }}
                >
                  Hi
                </Text>
                , <Text i18nKey="notifications.MCAFeedback.title">you have new feedback</Text>
              </b>
              <br />
              <Text i18nKey="notifications.MCAFeedback.subtitle">Click the link below to see it.</Text>
            </>
          }
          textStyle={{ width: 'unset', lineHeight: '1.7em' }}
          icon={<GreenSmiley style={{ margin: 2 }} />}
          success
          handleClose={() => setNewMCAFeedback(false)}
        />
      )}
      {showMCAFeedbackDialog && (
        <MCAFeedbackDialog
          close={() => {
            setShowMCAFeedbackDialog(false);
            setNewMCAFeedback(false);
          }}
          feedback={newMCAFeedback}
        />
      )}
    </>
  );
};

export default MCAFeedbackNotification;
